import { GroupIdentification } from '../types/groupIdentification';
import { LevelGroup } from '../types/levelGroup';
import { setGroupIdentification } from './setGroupIdentification';

// Utils
import { cloneDeep } from 'lodash';

const appCuesGroupProperties = [
    'Business Name'
    , 'Business Payment Type'
    , 'Created At'
    , 'District'
    , 'Industry'
    , 'Industry Sector'
    , 'Labor Pool Count'
    , 'Labor Pool Target'
    , 'Location Count'
    , 'Parent Business Id'
    , 'Posted Ops Count All Time'
    , 'Revenue Ytd'
    , 'Type of Operation'
    , 'Work Area Count'
    , 'Work Areas'
];

export const setBusinessGroup = (
    groupName: string
    , groupProperties: GroupIdentification[ 'groupProperties' ]
) => {
    const groupType = 'Business';
    const groupIdentification = {
        groupType
        , groupName
        , groupProperties: {
            ...groupProperties
        }
    };
    setGroupIdentification( groupIdentification, LevelGroup.User );
    try {
        if ( groupProperties ) {
            const appCuesGroupProps = cloneDeep( groupProperties );
            Object.keys( groupProperties )
                .filter( property => !appCuesGroupProperties.includes( property ) )
                .forEach( key => delete appCuesGroupProps[ key ] );
            window.Appcues?.group( groupName, appCuesGroupProps );
        }
    } catch ( error ) {
        console.error( 'AppCues - Error setting group properties', error );
    }
};
