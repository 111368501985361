import Business from '@/types/business';
import Suspension from '@/types/suspension';
import User from '@/types/user';
import WorkArea from '@/types/workarea';
import {
    ActionType
    , AsyncActionType
} from '../types';

export const LOGIN = 'bp/user/LOGIN';
export const LOGIN_SUCCESS = 'bp/user/LOGIN_SUCCESS';
export const LOGIN_ERROR = 'bp/user/LOGIN_ERROR';
export const LOGIN_WITH_GOOGLE = 'bp/user/LOGIN_WITH_GOOGLE';
export const LOGIN_WITH_GOOGLE_SUCCESS = 'bp/user/LOGIN_WITH_GOOGLE_SUCCESS ';
export const LOGIN_WITH_GOOGLE_ERROR = 'bp/user/LOGIN_WITH_GOOGLE_ERROR';
export const LOGOUT = 'bp/user/LOGOUT';
export const LOGOUT_SUCCESS = 'bp/user/LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'bp/user/LOGOUT_SUCCESS';
export const CHECK_FOR_SESSION = 'bp/user/CHECK_FOR_SESSION';
export const CHECK_FOR_SESSION_SUCCESS = 'bp/user/CHECK_FOR_SESSION_SUCCESS';
export const CHECK_FOR_SESSION_ERROR = 'bp/user/CHECK_FOR_SESSION_ERROR';
export const USER_SESSION_ERROR = 'bp/user/USER_SESSION_ERROR';
export const UPDATE_SUSPENSION = 'bp/user/UPDATE_SUSPENSION';
export const SET_SELECTED_LOCATION = 'bp/user/SET_SELECTED_LOCATION';
export const SET_SELECTED_BUSINESS = 'bp/user/SET_SELECTED_BUSINESS';
export const SET_SELECTED_BUSINESS_ID = 'bp/user/SET_SELECTED_BUSINESS_ID';
export const UPDATE_USER_STATE = 'bp/user/UPDATE_USER_STATE';
export const UPDATE_BUSINESS_PAYMENT_TYPE = 'bp/user/UPDATE_BUSINESS_PAYMENT_TYPE';
export const ADD_LOCATION = 'bp/user/ADD_LOCATION';
export const UPDATE_LOCATION = 'bp/user/UPDATE_LOCATION';
export const DELETE_LOCATION = 'bp/user/DELETE_LOCATION';
export const UPDATE_SELECTED_BUSINESS = 'bp/user/UPDATE_SELECTED_BUSINESS';
export const UPDATE_USER_INFO = 'bp/user/UPDATE_USER_INFO';
export const RESET = 'bp/user/RESET';
export const DISABLE_SHOW_MULTI_BUSINESS_USER_WARNING = 'bp/user/DISABLE_SHOW_MULTI_BUSINESS_USER_WARNING';
export const UPDATE_WORK_AREAS = 'bp/user/UPDATE_WORK_AREAS';
export const SET_USER_FIELDS = 'bp/user/SET_USER_FIELDS';
export interface UserState extends User {
    loggedIn: boolean;
    error: string;
    loginPending: boolean;
    sessionCheckPending: boolean;
    logoutPending: boolean;
    businesses: Array<Business>;
    selectedBusiness: Business;
    suspensions?: Array<Suspension>;
    lastViewedBusiness?: boolean;
    isInternalUser?: boolean;
    showMultiBusinessUserWarning: boolean;
    hasUserSessionError: boolean;
}

export interface UserInfoUpdate {
    email: string;
    firstName?: string;
    lastName?: string;
}

export interface State extends User {
    isLoggedIn: boolean;
    isLoginPending: boolean;
    errorMessage: string;
}

interface LoginWithGoogleAction extends AsyncActionType {
    type: typeof LOGIN_WITH_GOOGLE;
}

interface LoginWithGoogleSuccessAction extends AsyncActionType {
    type: typeof LOGIN_WITH_GOOGLE_SUCCESS;
}
interface LoginWithGoogleErrorAction extends AsyncActionType {
    type: typeof LOGIN_WITH_GOOGLE_ERROR;
}

interface LoginAction extends AsyncActionType {
    type: typeof LOGIN;
}

interface LoginSuccessAction extends AsyncActionType {
    type: typeof LOGIN_SUCCESS;
}

interface LoginErrorAction extends AsyncActionType {
    type: typeof LOGIN_ERROR;
}

interface LogoutAction extends AsyncActionType {
    type: typeof LOGOUT;
}

interface LogoutSuccessAction extends AsyncActionType {
    type: typeof LOGOUT_SUCCESS;
}

interface LogoutErrorAction extends AsyncActionType {
    type: typeof LOGOUT_ERROR;
}

interface CheckForSessionAction extends AsyncActionType {
    type: typeof CHECK_FOR_SESSION;
}

interface CheckForSessionSuccessAction extends AsyncActionType {
    type: typeof CHECK_FOR_SESSION_SUCCESS;
}

interface CheckForSessionErrorAction extends AsyncActionType {
    type: typeof CHECK_FOR_SESSION_ERROR;
}

interface UpdateSuspensionAction extends ActionType {
    type: typeof UPDATE_SUSPENSION;
}

interface SetSelectedLocationAction extends ActionType {
    type: typeof SET_SELECTED_LOCATION;
}

interface SetSelectedBusinessAction extends ActionType {
    type: typeof SET_SELECTED_BUSINESS;
}

interface SetSelectedBusinessIdAction extends ActionType {
    type: typeof SET_SELECTED_BUSINESS_ID;
    businessId: number;
}

interface UpdateUserStateAction extends ActionType {
    type: typeof UPDATE_USER_STATE;
}

interface UpdateBusinessPaymentMethodAction extends ActionType {
    type: typeof UPDATE_BUSINESS_PAYMENT_TYPE;
}

interface AddLocationAction extends ActionType {
    type: typeof ADD_LOCATION;
}

interface UpdateLocationAction extends ActionType {
    type: typeof UPDATE_LOCATION;
}

interface DeleteLocationAction extends ActionType {
    type: typeof DELETE_LOCATION;
}

interface UpdateSelectedBusinessAction extends ActionType {
    type: typeof UPDATE_SELECTED_BUSINESS;
}

interface UpdateUserInfoAction extends ActionType {
    type: typeof UPDATE_USER_INFO;
}

interface ResetAction extends ActionType {
    type: typeof RESET;
}

interface DisbaleShowMultiBusinessUserAction extends ActionType {
    type: typeof DISABLE_SHOW_MULTI_BUSINESS_USER_WARNING;
}

export interface UpdateWorkAreasAction extends ActionType {
    type: typeof UPDATE_WORK_AREAS;
    workAreas: WorkArea[];
}

export interface UserSessionErrorAction extends ActionType {
    type: typeof USER_SESSION_ERROR;
}

export type UserActionType = LoginAction
    | LoginSuccessAction
    | LoginErrorAction
    | LogoutAction
    | LogoutSuccessAction
    | LogoutErrorAction
    | CheckForSessionAction
    | CheckForSessionSuccessAction
    | CheckForSessionErrorAction
    | UpdateSuspensionAction
    | SetSelectedLocationAction
    | SetSelectedBusinessAction
    | SetSelectedBusinessIdAction
    | UpdateUserStateAction
    | UpdateBusinessPaymentMethodAction
    | AddLocationAction
    | UpdateLocationAction
    | UpdateSelectedBusinessAction
    | UpdateUserInfoAction
    | ResetAction
    | DisbaleShowMultiBusinessUserAction
    | UpdateWorkAreasAction
    | LoginWithGoogleAction
    | LoginWithGoogleSuccessAction
    | LoginWithGoogleErrorAction
    | UserSessionErrorAction
    | DeleteLocationAction;
