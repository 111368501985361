import { ampli } from 'src/ampli';
import { GroupIdentification } from '../types/groupIdentification';
import { LevelGroup } from '../types/levelGroup';

export const setUserGroup = (
    groupIdentification: GroupIdentification
    , levelGroup: LevelGroup
) => {
    if ( levelGroup === LevelGroup.User ) {
        // Sets user to the group
        ampli.client.setGroup( groupIdentification.groupType, groupIdentification.groupName );
    }
};
