import Cookies from 'js-cookie';

export const getCookie = ( name: string ) => {
    const cookie = Cookies.get( name );
    // The string return is needed for hubspot to work
    if ( !cookie ) {
        return '';
    }
    if ( cookie === 'undefined' ) {
        return '';
    }
    try {
        return JSON.parse( cookie );
    } catch ( e ) {
        return '';
    }
};

export const setCookie = ( name: string, value: any, expires = 7 ) => {
    Cookies.set( name, value, { expires } );
};

export const removeCookie = ( name: string ) => {
    Cookies.remove( name );
};
