import Business from '@/types/business';
import User from '@/types/user';
import { makeAPICall } from './axiosInstance';

export const authenticateChat = ( user: User, business: Business ) => {
    const jwtFn = async ( callback: ( jwt: string ) => void ) => {
        try {
            const result = await makeAPICall( {
                method: 'GET'
                , url: `/zendesk/chat-auth/${ user.id }`
            } );
            if ( !result || !result.token ) {
                console.error( 'No token in response from zendesk chat auth endpoint' );
                return;
            }
            callback( result.token );
        } catch ( e ) {
            if ( e && e instanceof Error ) {
                console.error( `Error getting zendesk chat credentials - ${ e.message }` );
            }
        }
    };

    try {
        if ( !user || !user.id ) {
            console.error( 'No user id present' );
        }
        if ( !window || (
            !window.$zopim
            && !window.zE
        ) ) {
            console.error( 'No authentication function available' );
        }
        if ( process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' && window.zE && window.zESettings ) {
            const district = user && user.district
                ? user.district
                : business && business.district
                    ? business.district
                    : '';
            let formattedDistrict = '';
            if ( district && district.length ) {
                formattedDistrict = `user_district_${ district.toLowerCase() }`;
            }

            window.zESettings = {
                webWidget: {
                    authenticate: {
                        chat: { jwtFn }
                    }
                    , contactOptions: {
                        enabled: true
                        , contactButton: { '*': 'Get in touch' }
                    }
                }
            };

            window.zE( () => {
                window.$zopim.livechat.authenticate( {
                    jwtFn
                } );
            } );
            window.zE( 'webWidget', 'prefill', {
                name: {
                    value: `${ user.firstName } ${ user.lastName }`
                    , readOnly: true
                }
                , email: {
                    value: user.email
                    , readOnly: true
                }
                , department: {
                    value: formattedDistrict
                    , readOnly: true
                }
            } );
        } else {
            console.log( `Skipping zendesk setup for environment ${ process.env.NEXT_PUBLIC_ENVIRONMENT || 'local' }` );
        }
    } catch ( e ) {
        if ( e && e instanceof Error ) {
            console.error( `Error authenticating zendesk chat - ${ e.message }` );
        }
    }
};
