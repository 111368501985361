import Business from '@/types/business';
import User from '@/types/user';

export const updateUserTracking = ( user: User, business: Business ) => {
    if ( !user || !user.email ) {
        console.error( 'No user to update in Hubspot' );
        return;
    }
    const onConversationsAPIReady = () => {
        try {
            const _hsq = ( window as any )._hsq = ( window as any )._hsq || [];
            const data = {
                email: user.email
                , company: business ? business.businessName : ''
                , district: business ? business.district : ''
            };
            _hsq.push( [ 'identify', data ] );
            // Trying to fake out Hubspot by tracking fake page -- should send id with it
            _hsq.push( [ 'trackPageView' ] );
        } catch ( e ) {
            if ( e && e instanceof Error ) {
                console.error( `Error setting user tracking - ${ e.message }` );
            }
        }
    };

    try {
        if ( ( window as any ).HubSpotConversations ) {
            onConversationsAPIReady();
        } else {
            ( window as any ).hsConversationsOnReady = [ onConversationsAPIReady ];
        }
    } catch ( e ) {
        if ( e && e instanceof Error ) {
            console.error( `Error updating hubspot user tracking info - ${ e.message }.` );
        }
    }
};

export const removeScript = () => {
    try {
        const el = document.getElementById( 'hs-script-loader' );
        if ( el && el.parentNode && el.parentNode.removeChild ) {
            el.parentNode.removeChild( el );
        }
    } catch ( e ) {
        if ( e && e instanceof Error ) {
            console.error( `Error removing child - ${ e.message }` );
        }
    }
};

export const loadScript = ( callback: () => void ) => {
    const script = document.createElement( 'script' );
    script.setAttribute( 'id', 'hs-script-loader' );
    script.src = '//js.hs-scripts.com/5382131.js';
    script.async = true;
    document.body.appendChild( script );
    script.addEventListener( 'load', callback );

};

export const reloadChat = () => {
    try {
        ( window as any ).HubSpotConversations.clear( { resetWidget: true } );

    } catch ( e ) {
        if ( e && e instanceof Error ) {
            console.error( `Error reloading chat - ${ e.message }` );
        }
    }
};

export const registerChatUser = () => {
    try {
        ( window as any ).HubSpotConversations.on( 'contactAssociated', () => {} );
    } catch ( e ) {
        if ( e && e instanceof Error ) {
            console.error( `Error registering chat user - ${ e.message }` );
        }
    }
};
