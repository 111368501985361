// Library imports
import { StreamChat } from 'stream-chat';

import { updateLogRocketTracking } from '@/utils/logrocket';

import { updateUserTracking as updateHubspotUserTracking } from '@/utils/hubspot';

import Business from '@/types/business';
import BusinessLocation from '@/types/businesslocation';
import User from '@/types/user';
import type WorkArea from '@/types/workarea';
import { authenticateChat } from '@/utils/zendesk';
import * as types from './types';

import IBusinessLocation from '@/types/businesslocation';
import { removeCookie } from '@/utils/cookies';
import findPrimaryLocation from '@/utils/findPrimaryLocation';

import { setBusinessGroup } from '@/amplitude/utils/setBusinessGroup';
import { ampli } from 'src/ampli';

import { formatInTimeZone } from 'date-fns-tz';
import _ from 'lodash';

const initialState: types.UserState = {
    id: 0
    , email: ''
    , firstName: ''
    , lastName: ''
    , district: ''
    , roleName: ''
    , businesses: []
    , loggedIn: false
    , error: ''
    , loginPending: false
    , sessionCheckPending: true
    , logoutPending: false
    , isInternalUser: false
    , isOperator: false
    , internalDistrict: ''
    , showMultiBusinessUserWarning: false
    , selectedBusiness: {
        id: 0
        , businessName: ''
        , level: 0
        , selectedLocation: {
            id: 0
            , name: ''
            , addressLine1: ''
            , addressLine2: ''
            , city: ''
            , state: ''
            , zip: ''
        }
        , isMarketplaceOn: false
        , currentLevel: ''
    }
    , hasUserSessionError: false
};

// Action Creators
export const login = ( loginInfo: {
    googleToken?: string;
    microsoftToken?: string;
    email?: string;
    password?: string;
} ): types.UserActionType => {
    return {
        type: types.LOGIN
        , meta: {
            async: true
            , method: 'POST'
            , path: '/users/login'
            , data: Object.assign( {}, loginInfo, {
                userType: 'business'
                , isQuickBusinessSelect: true
            } )
        }
    };
};

export const loginWithGoogle = ( idToken: string ): types.UserActionType => {
    return {
        type: types.LOGIN_WITH_GOOGLE
        , meta: {
            async: true
            , method: 'POST'
            , path: '/users/login'
            , data: {
                googleToken: idToken
                , isQuickBusinessSelect: true
                , userType: 'business'
            }
        }
    };
};

export const logout = (): types.UserActionType => {
    return {
        type: types.LOGOUT
        , meta: {
            async: true
            , method: 'PUT'
            , path: '/users/logout'
        }
    };
};

export const checkForSession = (): types. UserActionType => {
    return {
        type: types.CHECK_FOR_SESSION
        , meta: {
            async: true
            , method: 'GET'
            , path: '/users/check?isQuickBusinessSelect=true'
        }
    };
};

export const setUserSessionError = () => {
    return {
        type: types.USER_SESSION_ERROR
    };
};

export const setLoginError = ( errorMessage: string ): types.UserActionType => ( {
    type: types.LOGIN_ERROR
    , error: errorMessage
} );

export const setLoginSuccess = ( user: User ): types.UserActionType => ( {
    type: types.LOGIN_SUCCESS
    , data: user
} );

export const updateSuspension = ( suspensionId: number, suspensionStatus = 'pending' ): types.UserActionType => {
    return {
        type: types.UPDATE_SUSPENSION
        , data: {
            suspensionId
            , suspensionStatus
        }
    };
};

export const setSelectedLocation = ( locationId: number ) => {
    return {
        type: types.SET_SELECTED_LOCATION
        , data: locationId
    };
};

export const setSelectedBusiness = ( selectedBusiness: Business ) => {
    return {
        type: types.SET_SELECTED_BUSINESS
        , data: selectedBusiness
    };
};

export const setSelectedBusinessId = ( businessId: number ): types.UserActionType => {
    return {
        type: types.SET_SELECTED_BUSINESS_ID
        , businessId
    };
};

export const updateUserState = ( data: any ): types.UserActionType => {
    return {
        type: types.UPDATE_USER_STATE
        , data
    };
};

export const updateBusinessPaymentType = ( businessPaymentType: string ): types.UserActionType => {
    return {
        type: types.UPDATE_BUSINESS_PAYMENT_TYPE
        , data: {
            businessPaymentType
        }
    };
};

export const addLocation = ( location: IBusinessLocation ) => {
    return {
        type: types.ADD_LOCATION
        , data: location
    };
};

export const updateLocation = ( location: IBusinessLocation ): types.UserActionType => {
    return {
        type: types.UPDATE_LOCATION
        , data: location
    };
};

export const deleteLocation = ( locationId: number ): types.UserActionType => {
    return {
        type: types.DELETE_LOCATION
        , data: locationId
    };

};

export const updateSelectedBusiness = ( update: Business ) => {
    return {
        type: types.UPDATE_SELECTED_BUSINESS
        , data: update
    };
};

export const updateUserInfo = ( update: types.UserInfoUpdate ): types.UserActionType => {
    return {
        type: types.UPDATE_USER_INFO
        , data: update
    };
};

export const disableShowMultiBusinessUserWarning = (): types.UserActionType => {
    return {
        type: types.DISABLE_SHOW_MULTI_BUSINESS_USER_WARNING
    };
};

export const resetUser = (): types.UserActionType => {
    return {
        type: types.RESET
    };
};

export const updateWorkAreas = ( workAreas: WorkArea[] ): types.UpdateWorkAreasAction => {
    return {
        type: types.UPDATE_WORK_AREAS
        , workAreas
    };

};

const findSelectedBusiness = ( businesses: Business[], lastViewedId = 0 ) => {
    if ( !businesses || !Array.isArray( businesses ) || !businesses.length ) {
        return initialState.selectedBusiness;
    }
    let selectedBusiness;
    if ( businesses.length === 1 ) {
        selectedBusiness = businesses[ 0 ];
    } else if ( lastViewedId ) {
        const lastViewedBusiness = businesses.find( ( business: Business ) => {
            return lastViewedId === business.id;
        } );
        if ( lastViewedBusiness && lastViewedBusiness.workAreas ) {
            selectedBusiness = lastViewedBusiness;
        }
    }
    if ( !selectedBusiness ) {
        const primaryBusiness = businesses.find( ( business: Business ) => {
            return business.isPrimaryBusiness === true;
        } );
        if ( primaryBusiness ) {
            selectedBusiness = primaryBusiness;
        }
    }
    if ( !selectedBusiness && businesses.length ) {
        selectedBusiness = businesses[ 0 ];
    }
    if ( !selectedBusiness ) {
        return initialState.selectedBusiness;
    }
    let selectedLocation = initialState.selectedBusiness.selectedLocation;
    if ( selectedBusiness.locations ) {
        const primaryLocation = findPrimaryLocation( selectedBusiness.locations );
        if ( primaryLocation ) {
            selectedLocation = primaryLocation;
        }
    }
    return Object.assign( {}, selectedBusiness, { selectedLocation } );
};

const checkIfInternalUser = ( user: User ) => {
    //eslint-disable-next-line
    if ( !user || !user.hasOwnProperty( 'email' )  ) {
        return false;
    }
    return user.isInternalUser === true;
};

const sortBusinessLocations = ( businessLocations: any[] | undefined ) => {
    if ( businessLocations && businessLocations.length > 1 ) {
        businessLocations.sort( ( a, b ) => {
            return Number( b.isPrimary ) - Number( a.isPrimary );
        } );
    }
};

const formatBusinessesForSelect = ( businesses: Business[] ): Business[] => {
    return businesses.map( ( business: Business, index: number, arr: Business[] ) => {
        const sameCityBusiness = arr.find( ( el: Business ) => {
            return el.id !== business.id
            && el.city === business.city
            && el.businessName === business.businessName;
        } );
        return Object.assign( {}, business, {
            showAddressOnSelect: !!sameCityBusiness
        } );

    } );
};

// Reducer
export default function reducer ( state: types.UserState = initialState, action: types.UserActionType ) {
    let selectedBusiness: Business;
    let selectedLocation;
    switch ( action.type ) {
        case types.LOGIN:
            return Object.assign(
                {}
                , state,
                {
                    error: ''
                    , loginPending: true
                    , loggedIn: false
                }
            );
        case types.UPDATE_USER_STATE:
        case types.LOGIN_SUCCESS:
            selectedBusiness = findSelectedBusiness(
                action.data && action.data.businesses ? action.data.businesses : []
                , action.data && action.data.user ? action.data.user.lastBusinessViewed : 0
            );
            window.Appcues?.identify( action.data.user.id, {
                'User Id': action.data.user.id
                , 'Is Internal User': action.data.user.isInternalUser
                , 'Email': action.data.user.email
                , 'District': selectedBusiness?.district || 'N/A'
                , 'Internal District': action.data.user.isInternalUser ? action.data.user.internalDistrict : 'N/A'
                , 'Role Name': action.data.user.roleName
                , 'User Type': _.capitalize( action.data.user.isInternalUser ? action.data.user.type : action.data.user.usertype )
                , 'First Name': action.data.user.firstName
                , 'Full Name': `${ action.data.user.firstName } ${ action.data.user.lastName }`
            } );
            if ( updateLogRocketTracking ) {
                updateLogRocketTracking( action.data.user, selectedBusiness );
            }
            updateHubspotUserTracking( action.data.user, selectedBusiness );
            authenticateChat( action.data.user, selectedBusiness );
            sortBusinessLocations( selectedBusiness.locations );
            const isInternalUser = checkIfInternalUser( action.data.user );
            // Set User Identification
            ampli.identify(
                `${ action.data.user.id }`
                , {
                    'User Id': action.data.user.id
                    , 'Is Internal User': action.data.user.isInternalUser
                    , 'Email': action.data.user.email
                    , 'District': action.data.user.district
                    , 'Internal District': action.data.user.isInternalUser ? action.data.user.internalDistrict : 'N/A'
                    , 'Role Name': action.data.user.roleName
                    , 'User Type': _.capitalize( action.data.user.isInternalUser ? action.data.user.type : action.data.user.usertype )
                }
            );
            // Track Amplitude Logged In Event
            ampli.loggedIn( {
                'Logged In With Google': !!action?.meta?.data.googleToken
                , 'Is Multi Portal Business User': selectedBusiness.id === 0
            } );
            // If not a multi portal business user
            if ( selectedBusiness.id !== 0 ) {
                const workAreas = selectedBusiness?.workAreas?.map( ( workArea: WorkArea ) => workArea.name ) || [];
                // Set Business Group identification
                setBusinessGroup( `${ selectedBusiness.id }`, {
                    'Parent Business Id': selectedBusiness.parentBusinessId ?? 'N/A'
                    , 'Business Name': selectedBusiness.businessName
                    , 'Created At': selectedBusiness.createdAt ? formatInTimeZone( new Date ( selectedBusiness.createdAt ), selectedBusiness?.timeZoneId || 'America/Chicago', 'yyyy-MM-dd' ) : ''
                    , 'Business Payment Type': selectedBusiness.businessPaymentType
                    , 'District': selectedBusiness.district
                    , 'Location Count': Number( selectedBusiness?.locations?.length )
                    , 'Posted Ops Count All Time': Number( selectedBusiness?.postedOpsCountAllTime )
                    , 'Work Area Count': Number( selectedBusiness?.workAreas?.length )
                    , 'Industry': ''
                    , 'Labor Pool Count': 0
                    , 'Labor Pool Target': 0
                    , 'Revenue Ytd': 0
                    , 'Work Areas': workAreas
                } );
                ampli.businessPortalEntered( {
                    'Business Id': String( selectedBusiness.id )
                    , 'Business Name': selectedBusiness.businessName
                    , 'Locations': selectedBusiness?.locations?.map( location => location.name ) || []
                    , 'User Role': action.data.user.roleName
                    , 'Work Areas': workAreas
                } );
            }
            return Object.assign(
                {}
                , state
                , {
                    loginPending: false
                    , id: action.data.user.id
                    , email: action.data.user.email
                    , firstName: action.data.user.firstName
                    , lastName: action.data.user.lastName
                    , roleName: action.data.user.roleName
                    , isOperator: action.data.user.isOperator
                    , internalDistrict: action.data.user.internalDistrict ? action.data.user.internalDistrict : ''
                    , businesses: isInternalUser
                        ? action.data.businesses
                        : formatBusinessesForSelect( action.data.businesses )
                    , loggedIn: true
                    , createdAt: action.data.user.createdAt
                    , selectedBusiness
                    , isInternalUser
                    , hasUserSessionError: false
                }
            );
        case types.LOGIN_ERROR: {
            const {
                status = null
                , data = null
            } = action.payload.response || {};
            return Object.assign(
                {}
                , state
                , {
                    loggedIn: false
                    , loginPending: false
                    , sessionCheckPending: false
                    , selectedBusiness: ( status === 401 || 403 ) && data && data.business
                        ? data.business
                        : initialState.selectedBusiness
                    , suspensions: ( status === 401 || 403 ) && data && data.suspensions && data.suspensions.length
                        ? data.suspensions
                        : undefined
                    , error: ( status === 401 || 403 ) && data && data.suspensions && data.suspensions.length
                        ? ''
                        : data?.clientMessage
                            ? data.clientMessage
                            : data?.message?.indexOf( 'no business' ) > -1
                                ? 'We don\'t recognize your account. Please select a different account or try again.'
                                : data?.message?.indexOf( 'has been deactivated' ) > -1
                                    ? data.message
                                    : 'We experienced a network error. Please try again or contact support.'
                }
            );
        }
        case types.LOGIN_WITH_GOOGLE:
            return Object.assign( {}, state, {
                googleToken: ''
                , error: ''
                , loginPending: true
                , loggedIn: false
            } );
        case types.LOGIN_WITH_GOOGLE_SUCCESS:
            selectedBusiness = findSelectedBusiness(
                action.data && action.data.businesses ? action.data.businesses : []
                , action.data && action.data.user ? action.data.user.lastBusinessViewed : 0
            );
            sortBusinessLocations( selectedBusiness.locations );
            const isInternalUser2 = checkIfInternalUser( action.data.user );

            return Object.assign(
                {}
                , state
                , {
                    loginPending: false
                    , id: action.data.user.id
                    , email: action.data.user.email
                    , firstName: action.data.user.firstName
                    , lastName: action.data.user.lastName
                    , isOperator: action.data.user.isOperator
                    , internalDistrict: action.data.user.internalDistrict ? action.data.user.internalDistrict : ''
                    , businesses: isInternalUser2
                        ? action.data.businesses
                        : formatBusinessesForSelect( action.data.businesses )
                    , loggedIn: true
                    , createdAt: action.data.user.createdAt
                    , selectedBusiness
                    , isInternalUser2
                    , hasUserSessionError: false
                }
            );
        case types.LOGIN_WITH_GOOGLE_ERROR: {
            const {
                status = null
                , data = null
            } = action?.payload?.response || {};
            return Object.assign(
                {}
                , state
                , {
                    loggedIn: false
                    , loginPending: false
                    , selectedBusiness: status === 401 && data && data.business
                        ? data.business
                        : initialState.selectedBusiness
                    , suspensions: status === 401 && data && data.suspensions && data.suspensions.length
                        ? data.suspensions
                        : undefined
                    , error: status === 401 && data && data.suspensions && data.suspensions.length
                        ? ''
                        : data?.clientMessage
                            ? data.clientMessage
                            : data?.message?.indexOf( 'no business' ) > -1
                                ? 'We don\'t recognize your account. Please select a different account or try again.'
                                : data?.message?.indexOf( 'has been deactivated' ) > -1
                                    ? data.message
                                    : 'We experienced a network error. Please try again or contact support.'
                }
            );
        }
        case types.LOGOUT:
            return Object.assign( {}, state, { logoutPending: true } );
        case types.LOGOUT_SUCCESS:
            removeCookie( 'skipSessionCheck' );
            // Disconnects user from stream
            const apiKey = process.env.NEXT_PUBLIC_STREAM_KEY;
            if ( !apiKey ) {
                console.error( 'App unauthorized.' );
                return Object.assign( {}, initialState );
            }
            const streamChatClient = StreamChat.getInstance( apiKey );
            if ( streamChatClient ) {
                streamChatClient?.disconnectUser();
            } else {
                console.error( 'Error disconnecting the business user from Stream' );
                return Object.assign( {}, initialState );
            }
            return Object.assign( {}, initialState );
        case types.LOGOUT_ERROR:
            return Object.assign( {}, state, {
                loggedIn: false
                , error: ''
                , logoutPending: false
            } );
        case types.CHECK_FOR_SESSION:
            return Object.assign( {}, state, { sessionCheckPending: true } );
        case types.CHECK_FOR_SESSION_SUCCESS: {
            selectedBusiness = findSelectedBusiness(
                action.data && action.data.businesses ? action.data.businesses : []
                , action.data && action.data.user ? action.data.user.lastBusinessViewed : 0
            );
            sortBusinessLocations( selectedBusiness.locations );
            const isInternalUser = checkIfInternalUser( action.data.user );
            if ( action?.data?.user ) {
                window.Appcues?.identify( action.data.user.id, {
                    'User Id': action.data.user.id
                    , 'Is Internal User': action.data.user.isInternalUser
                    , 'Email': action.data.user.email
                    , 'District': selectedBusiness?.district || 'N/A'
                    , 'Internal District': action.data.user.isInternalUser ? action.data.user.internalDistrict : 'N/A'
                    , 'Role Name': action.data.user.roleName
                    , 'User Type': _.capitalize( action.data.user.isInternalUser ? action.data.user.type : action.data.user.usertype )
                    , 'First Name': action.data.user.firstName
                    , 'Full Name': `${ action.data.user.firstName } ${ action.data.user.lastName }`
                } );
                if ( updateLogRocketTracking ) {
                    updateLogRocketTracking( action.data.user, selectedBusiness );
                }
                updateHubspotUserTracking( action.data.user, selectedBusiness );
                authenticateChat( action.data.user, selectedBusiness );
                const workAreas = selectedBusiness?.workAreas?.map( workArea => workArea.name ) || [];
                // Set User Identification
                ampli.identify(
                    `${ action.data.user.id }`
                    , {
                        'User Id': action.data.user.id
                        , 'Is Internal User': action.data.user.isInternalUser
                        , 'Email': action.data.user.email
                        , 'District': action.data.user.district
                        , 'Internal District': action.data.user.isInternalUser ? action.data.user.internalDistrict : 'N/A'
                        , 'Role Name': action.data.user.roleName
                        , 'User Type': _.capitalize( action.data.user.isInternalUser ? action.data.user.type : action.data.user.usertype )
                    }
                );
                ampli.businessPortalEntered( {
                    'Business Id': String( selectedBusiness.id )
                    , 'Business Name': selectedBusiness.businessName
                    , 'Locations': selectedBusiness?.locations?.map( location => location.name ) || []
                    , 'User Role': action.data.user.roleName
                    , 'Work Areas': workAreas
                } );
            }
            return Object.assign(
                {}
                , state
                , {
                    sessionCheckPending: false
                    , id: action.data && action.data.user
                        ? action.data.user.id
                        : 0
                    , email: action.data && action.data.user
                        ? action.data.user.email
                        : ''
                    , firstName: action.data && action.data.user
                        ? action.data.user.firstName
                        : ''
                    , lastName: action.data && action.data.user
                        ? action.data.user.lastName
                        : ''
                    , roleName: action.data && action.data.user
                        ? action.data.user.roleName
                        : ''
                    , isOperator: action.data && action.data.user
                        ? action.data.user.isOperator
                        : false
                    , internalDistrict: action.data.user.internalDistrict
                        ? action.data.user.internalDistrict
                        : ''
                    , businesses: action.data && action.data.businesses
                        ? isInternalUser
                            ? action.data.businesses
                            : formatBusinessesForSelect( action.data.businesses )
                        : []
                    , loggedIn: true
                    , createdAt: action.data && action.data.user
                        ? action.data.user.createdAt
                        : ''
                    , selectedBusiness: {
                        ...state.selectedBusiness
                        , ...selectedBusiness
                    }
                    , isInternalUser
                    , showMultiBusinessUserWarning: action.data && action.data.businesses && action.data.businesses.length > 1 && !isInternalUser && selectedBusiness && !selectedBusiness.isPrimaryBusiness
                    , hasUserSessionError: false
                }
            );
        }

        case types.CHECK_FOR_SESSION_ERROR: {
            const {
                status = null
                , data = null
            } = action.payload.response || {};
            return Object.assign(
                {}
                , state
                , {
                    loggedIn: false
                    , sessionCheckPending: false
                    , selectedBusiness: ( status === 401 || 403 ) && data && data.business
                        ? data.business
                        : initialState.selectedBusiness
                    , suspensions: ( status === 401 || 403 ) && data && data.suspensions && data.suspensions.length
                        ? data.suspensions
                        : undefined
                    , error: ''
                }
            );
        }
        case types.USER_SESSION_ERROR: {
            return Object.assign(
                {}
                , state
                , {
                    hasUserSessionError: true
                }
            );
        }
        case types.UPDATE_SUSPENSION:
            let suspensions = state.suspensions;
            if ( suspensions ) {
                suspensions = suspensions.slice();
                const foundSuspensionIndex = suspensions.findIndex( suspension => suspension.suspensionId === action.data.suspensionId );
                suspensions[ foundSuspensionIndex ][ 'suspensionStatus' ] = action.data.suspensionStatus;
            }
            return Object.assign( {}, state, { suspensions } );
        case types.SET_SELECTED_LOCATION:
            if ( state.selectedBusiness && state.selectedBusiness.locations && state.selectedBusiness.locations.length ) {
                selectedLocation = state.selectedBusiness.locations
                    .find( ( location: BusinessLocation ) => location.id === action.data );
            }
            if ( !selectedLocation ) {
                selectedLocation = state.selectedBusiness.selectedLocation;
            }
            return Object.assign(
                {}
                , state
                , {
                    selectedBusiness: Object.assign(
                        {}
                        , state.selectedBusiness
                        , {
                            selectedLocation
                        }
                    )
                }
            );
        case types.SET_SELECTED_BUSINESS_ID:
            selectedBusiness = state.businesses.find( ( business: Business ) => business.id === action.businessId ) || state.businesses[ 0 ];
            window.Appcues?.identify( state.id, {
                'User Id': state.id
                , 'Is Internal User': state.isInternalUser
                , 'Email': state.email
                , 'District': selectedBusiness?.district || 'N/A'
                , 'Internal District': state.isInternalUser ? state.internalDistrict : 'N/A'
                , 'Role Name': state.roleName
                , 'First Name': state.firstName
                , 'Full Name': `${ state.firstName } ${ state.lastName }`
            } );
            if ( updateLogRocketTracking ) {
                updateLogRocketTracking( state, selectedBusiness );
            }
            updateHubspotUserTracking( state, selectedBusiness );
            sortBusinessLocations( selectedBusiness.locations );
            return Object.assign( {}, state, {
                selectedBusiness: Object.assign( {}, selectedBusiness, {
                    level: state.isInternalUser
                        ? 4
                        : selectedBusiness.level
                } )
                , showMultiBusinessUserWarning: false
            } );
        case types.SET_SELECTED_BUSINESS:
            selectedBusiness = findSelectedBusiness( [ action.data ] );

            const {
                id
                , email
                , firstName
                , lastName
                , isOperator
            } = state;
            const user: User = {
                id
                , email
                , firstName
                , lastName
                , isOperator
            };
            window.Appcues?.identify( state.id, {
                'User Id': state.id
                , 'Is Internal User': state.isInternalUser
                , 'Email': state.email
                , 'District': selectedBusiness?.district || 'N/A'
                , 'Internal District': state.isInternalUser ? state.internalDistrict : 'N/A'
                , 'Role Name': state.roleName
                , 'First Name': state.firstName
                , 'Full Name': `${ state.firstName } ${ state.lastName }`
            } );
            if ( updateLogRocketTracking ) {
                updateLogRocketTracking( user, selectedBusiness );
            }
            updateHubspotUserTracking( user, selectedBusiness );
            // No zendesk chat auth needed (only one user)
            sortBusinessLocations( selectedBusiness.locations );
            return Object.assign( {}, state, {
                selectedBusiness: Object.assign( {}, selectedBusiness, {
                    level: state.isInternalUser
                        ? 4
                        : selectedBusiness.level
                } )
                , showMultiBusinessUserWarning: state.businesses.length > 1 && !state.isInternalUser && selectedBusiness && !selectedBusiness.isPrimaryBusiness
            } );
        case types.UPDATE_BUSINESS_PAYMENT_TYPE:
            return Object.assign( {}, state, {
                selectedBusiness: Object.assign( {}, state.selectedBusiness, {
                    businessPaymentType: action.data.businessPaymentType
                } )
            } );
        case types.ADD_LOCATION: {

            selectedBusiness = Object.assign( {}, state.selectedBusiness );

            if ( !selectedBusiness.locations ) return state;

            const locations = [];
            for ( const location of selectedBusiness.locations ) {
                if ( action.data[ 'isPrimary' ] ) {
                    locations.push( Object.assign( {}, location, { isPrimary: false } ) );
                } else {
                    locations.push( location );
                }
            }
            if ( action.data[ 'isPrimary' ] ) {
                locations.unshift( action.data );
            } else {
                locations.push( action.data );
            }

            selectedBusiness = Object.assign( {}, selectedBusiness, { locations } );
            sortBusinessLocations( selectedBusiness.locations );

            return Object.assign( {}, state, { selectedBusiness } );
        }
        case types.UPDATE_LOCATION: {

            selectedBusiness = state.selectedBusiness;

            if ( !selectedBusiness.locations ) return state;

            const locations = [];
            for ( const location of selectedBusiness.locations ) {
                if ( location.id === action.data[ 'id' ] ) {
                    if ( !action.data[ 'isRemoved' ] ) {
                        locations.push( action.data );
                    }
                } else if ( action.data[ 'isPrimary' ] ) {
                    locations.push( Object.assign( {}, location, { isPrimary: false } ) );
                } else {
                    locations.push( location );
                }
            }

            selectedBusiness = Object.assign( {}, selectedBusiness, { locations } );
            sortBusinessLocations( selectedBusiness.locations );

            return Object.assign( {}, state, { selectedBusiness } );
        }
        case types.DELETE_LOCATION: {

            selectedBusiness = state.selectedBusiness;

            if ( !selectedBusiness.locations ) return state;

            const locations = [];
            for ( const location of selectedBusiness.locations ) {
                if ( location.id !== action.data ) {
                    locations.push( location );
                }
            }

            selectedBusiness = Object.assign( {}, selectedBusiness, { locations } );
            sortBusinessLocations( selectedBusiness.locations );

            return Object.assign( {}, state, { selectedBusiness } );
        }
        case types.UPDATE_SELECTED_BUSINESS:
            selectedBusiness = Object.assign( {}, state.selectedBusiness, action.data );
            selectedBusiness = findSelectedBusiness( [ selectedBusiness ] );
            return Object.assign( {}, state, {
                selectedBusiness
                , businesses: state.businesses.map( ( business: Business ) => {
                    if ( business.id === selectedBusiness.id ) {
                        return selectedBusiness;
                    }
                    return business;
                } )
            } );
        case types.UPDATE_USER_INFO:
            return Object.assign( {}, state, action.data );
        case types.RESET:
            return Object.assign( {}, initialState );
        case types.DISABLE_SHOW_MULTI_BUSINESS_USER_WARNING:
            return Object.assign( {}, state, { showMultiBusinessUserWarning: false } );
        case types.UPDATE_WORK_AREAS:
            return {
                ...state
                , selectedBusiness: {
                    ...state.selectedBusiness
                    , workAreas: action.workAreas
                }
            };
        default:
            return state;
    }
}
