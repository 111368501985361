import * as amplitude from '@amplitude/analytics-browser';
import { ampli } from 'src/ampli';
import { GroupIdentification } from '../types/groupIdentification';
import { LevelGroup } from '../types/levelGroup';
import { setUserGroup } from './setUserGroup';

export const setGroupIdentification = (
    groupIdentification: GroupIdentification
    , levelGroup: LevelGroup
) => {
    const groupType = groupIdentification.groupType;
    // Usually an ID
    const groupName = `${ groupIdentification.groupName }`;
    const groupIdentifyObj = new amplitude.Identify();

    for ( const [ key, value ] of Object.entries( groupIdentification?.groupProperties || {} ) ) {
        groupIdentifyObj.set( `${ key }`, value );
    }

    setUserGroup( groupIdentification, levelGroup );

    // Set and update group properties
    ampli.client.groupIdentify( groupType, groupName, groupIdentifyObj );
};
